<template>
    <div>
        <a-row>
            <a-col :span="24" style="padding-bottom: 15px">
                <a-form-model layout="inline" @submit.native.prevent>
                    <a-form-model-item label="">
                        <a-input v-model="formInline.name" placeholder="请输入执行标准名称或编号" @keyup="Get_documentpage" style="width: 300px"></a-input>
                    </a-form-model-item>
                    <a-form-model-item label="">
                        <a-radio-group name="radioGroup" v-model="formInline.status" @change="Get_documentpage">
                            <a-radio :value="1">使用中</a-radio>
                            <a-radio :value="2">已停用</a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                    <a-form-model-item label="" :label-col="{ span: 0, offset: 0 }" :wrapper-col="{ span: 24, offset: 0 }">
                        <a-button @click="Clear()" type="danger" class=""> 清空搜索</a-button>
                    </a-form-model-item>
                </a-form-model>
            </a-col>
        </a-row>
        <a-row>
            <a-col :span="24">
                <a-col :span="24">
                    <a-space style="margin-bottom: -12px; background: #ebf8fe; border: none" class="btner">
                        <div class="btnerlist" style="cursor: text;" v-if="item_info.type !== 0 && item_info.det_item_ids !== '' " @click="showDrawer({
            id:0,
            name:'',
            serial:'',
            file_url:'',
            remark:'',
            status:'',
            })">
                            <i class="iconfont icon-fuzhi1" style="font-size: 15px; vertical-align: -1px"></i>新增执行标准
                        </div>
                        <div class="btnerlist" @click="showDrawer1()">
                            <i class="iconfont icon-yangbenluru_huaban1" style="font-size: 15px; vertical-align: -1px"></i>编辑
                        </div>
                        <div class="btnerlist" @click="showDrawer2()">
                            <i class="iconfont icon-yangpinmoban" style="font-size: 15px; vertical-align: -1px"></i>历史记录
                        </div>
                        <div class="btnerlist">
                            <a-popconfirm placement="top" ok-text="确定" cancel-text="取消" okType="danger" @confirm="delClick()">
                                <template slot="title">确认删除该项？</template>
                                <i class="iconfont icon-yixiaohui" style="font-size: 15px; vertical-align: -1px"></i>删除
                            </a-popconfirm>
                        </div>
                    </a-space>
                </a-col>
            </a-col>
            <a-col :span="24">
                <table class="layui-table" lay-size="sm" style="margin-top: 11px">
                    <thead>
                    <tr>
                        <th>
                            <a-checkbox v-model="all_check" @change="allChange"></a-checkbox>
                        </th>
                        <th @click="paixuclickup(1)" style="cursor: pointer; width: 300px">
                            <span>所属分类</span>
                            <div style="display: inline-block; margin-top: -5px">
                             <span class="ant-table-column-sorter">
                           <a-icon type="caret-up" class="jiantou" :class="[ordertype === 1 && sortrule === 'asc' ?'classname':'']"/>
                           <a-icon type="caret-down" class="jiantou" :class="[ordertype === 1 && sortrule === 'desc' ?'classname':'']" style=" margin-top: -5px"/>
                        </span>
                            </div>
                        </th>
                        <th @click="paixuclickup(2)" style="cursor: pointer; width: 30% ">
                            <span>执行标准名称</span>
                            <span class="ant-table-column-sorter">
                           <a-icon type="caret-up" class="jiantou" :class="[ordertype === 2 && sortrule === 'asc' ?'classname':'']"/>
                           <a-icon type="caret-down" class="jiantou" :class="[ordertype === 2 && sortrule === 'desc' ?'classname':'']" style=" margin-top: -5px"/>
                        </span>
                        </th>
                        <!--                    <th @click="paixuclickup(3)" style="cursor: pointer">-->
                        <!--                        <span class="fl">标准编号</span>-->
                        <!--                        <span class="ant-table-column-sorter fl">-->
                        <!--                                    <a-icon type="caret-up" class="jiantou" :class="[ordertype === 3 && sortrule === 'asc' ?'classname':'']"/>-->
                        <!--                                    <a-icon type="caret-down" class="jiantou" :class="[ordertype === 3 && sortrule === 'desc' ?'classname':'']" style=" margin-top: -5px"/>-->
                        <!--                                    </span>-->
                        <!--                    </th>-->
                        <!--                    <th style="width:250px">执行标准文档名称</th>-->
                        <th>状态</th>
                        <th>创建人</th>
                        <th>创建时间</th>
                        <th>最后修改时间</th>
                        <th>备注</th>
                        <!--                        <th>操作</th>-->
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item,key) in document_list" :key="key" @click="clickrow(key)" :class="[item.check ? 'active':'']">
                        <td style="text-align: center">
                            <a-checkbox v-model="item.check"></a-checkbox>
                        </td>
                        <td>{{item.det_item_name}}</td>
                        <td>
                            <div @click="urlJump(item.file_url)"><a target='_blank'>{{item.name}}</a></div>
                            <div><a>{{item.serial}}</a></div>
                        </td>
                        <td>{{wordType(item.status)}}</td>
                        <td>{{item.admin_name}}</td>
                        <td>{{item.create_time}}</td>
                        <td>
                            <span v-if="item.updatetime===null">{{item.create_time}}</span>
                            <span v-else> {{item.updatetime}}</span>
                        </td>
                        <td style=" cursor: pointer; width: 200px">
                            <a-tooltip>
                                <template slot="title" v-if="item.remark !== ''">
                                    <span> {{item.remark}}</span>
                                </template>
                                <div style="width: 200px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                                    <span v-if="item.remark !== ''">{{item.remark}}</span>
                                    <span v-if="item.remark === ''"></span>
                                </div>
                            </a-tooltip>
                        </td>
                        <!--                        <td style="text-align: left">-->
                        <!--                            <a-button type="link" class="ml5" @click="showDrawer1(item)" size="small">编辑</a-button>-->
                        <!--                            <a-button type="link" @click="showDrawer2(item)" size="small" class="yellow">历史记录</a-button>-->
                        <!--                            <a-popconfirm placement="top" ok-text="确定" cancel-text="取消" okType="danger" @confirm="delClick(item)">-->
                        <!--                                <template slot="title">-->
                        <!--                                    确认删除该项？-->
                        <!--                                </template>-->
                        <!--                                <a-button type="link" class="ml5" size="small" style=" color:#ff0000">-->
                        <!--                                    删除-->
                        <!--                                </a-button>-->
                        <!--                            </a-popconfirm>-->
                        <!--                        </td>-->
                    </tr>
                    </tbody>
                </table>
                <a-pagination :current="page" :pageSize="pagesize" :total="count" v-if="count>pagesize" @change="onChange"/>
            </a-col>
        </a-row>
        <div>
            <!--新建弹窗-->
            <div v-if="edit_info">
                <Creatbiaozhun :edit_time="edit_time" :edit_info="edit_info" :close="onCloseDrawer" :visible="drawer_visible" :item_info="item_info"></Creatbiaozhun>
            </div>
            <!--编辑弹窗-->
            <div v-if="edit_info1">
                <Editbiaozhun :edit_time="edit_time1" :edit_info="edit_info1" :close="onCloseDrawer1" :visible="drawer_visible1"></Editbiaozhun>
            </div>
            <div v-if="edit_info2">
                <Historylist :edit_time="edit_time2" :edit_info="edit_info2" :close="onCloseDrawer2" :visible="drawer_visible2"></Historylist>
            </div>
            <!--历史记录-->
        </div>
    </div>
</template>
<script>
    import Creatbiaozhun from '../creatbiaozhun/creatbiaozhun.vue';
    import Editbiaozhun from '../editbiaozhun/editbiaozhun.vue';
    import Historylist from '../lishilist/lishilist.vue';

    export default {
        name: "creatitem",
        components: {
            Creatbiaozhun, Editbiaozhun, Historylist
        },
        props: ["reload", "item_info", "deep_info"],
        data() {
            return {
                drawer_visible: false,
                drawer_visible1: false,
                formInline: {
                    name: '',
                    status: ''
                },
                document_list: [],
                edit_time: 0,
                edit_info: false,
                edit_time1: 0,
                edit_info1: false,
                edit_time2: 0,
                edit_info2: false,
                // 分页
                page_show: false,
                page: 1,
                count: 0,
                pagesize: 0,
                ordertype: 0,
                sortrule: 'asc',
                all_check: false,
            }
        },
        watch: {
            itemActiveChange() {
                let do_ = true
                this.document_list.map((item, key) => {
                    if (!item.check) do_ = false
                })
                this.all_check = do_
            },
            allChange(e) {
                this.document_list.map((item, key) => {
                    this.$set(this.document_list[key], 'check', this.all_check)
                })
            },
        },
        mounted() {
            this.mountedDo()

        },
        methods: {

            itemActiveChange() {
                let do_ = true
                this.document_list.map((item, key) => {
                    if (!item.check) do_ = false
                })
                this.all_check = do_
            },
            allChange(e) {
                this.document_list.map((item, key) => {
                    this.$set(this.document_list[key], 'check', this.all_check)
                })
            },


            urlJump(url) {
                if (url === "") {
                    layer.msg("还没有上传执行标准文档，请上传后下载")
                } else
                    window.open(url,)
            },

            Clear() {
                this.formInline.name = '';
                this.formInline.status = '';
                this.page = 1;
                this.Get_document();
            },
            mountedDo() {
                this.Get_document();
            },

            clickrow(key) {
                this.$set(this.document_list[key], 'check', !this.document_list[key].check)
                this.itemActiveChange()
            },

            Get_documentpage() {
                this.page = 1
                this.Get_document()

            },

            //获取所有文档列表
            Get_document() {
                this.$sa0.post({
                    url: this.$api('Get_executive_bz'),
                    data: {
                        page: this.page,
                        name: this.formInline.name,
                        status: this.formInline.status,
                        ordertype: this.ordertype,
                        sortrule: this.sortrule,
                        item_id: this.item_info.id
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            // this.document_list = response.data.result.list;
                            this.document_list = response.data.result.list.map((item) => {
                                item.check = false
                                return item;
                            });
                            this.count = Number(response.data.result.count);
                            this.pagesize = Number(response.data.result.pagesize);

                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            paixuclickup(ordertype) {
                if (this.ordertype !== ordertype) {
                    this.ordertype = ordertype
                    this.sortrule = 'asc'
                } else {
                    if (this.sortrule === 'asc') {
                        this.sortrule = 'desc'
                    } else {
                        this.sortrule = 'asc'
                    }
                }
                this.$sa0.post({
                    url: this.$api('Get_executive_bz'),
                    data: {
                        page: this.page,
                        name: this.formInline.name,
                        status: this.formInline.status,
                        ordertype: this.ordertype,
                        sortrule: this.sortrule,
                        item_id: this.item_info.id
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.document_list = response.data.result.list;
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            //删除化学式
            delClick() {
                // let id = 0
                // let count = 0
                // this.document_list.map((item, key) => {
                //     if (item.check) {
                //         id = item.id
                //         count++
                //     }
                // })
                // if (!id) return layer.msg('请选择执行标准')
                // if (count !== 1) return layer.msg('只能选择一个择执行标准进行删除')

                let executive_ids = []
                this.document_list.map(item => {
                    if (item.check) {
                        executive_ids.push(Number(item.id))
                    }
                })
                if (executive_ids.length < 1) {
                    layer.msg('请勾选需要操作的执行标准')
                    return
                }


                this.$sa0.post({
                    url: this.$api('Del_executive_bz'),
                    data: {
                        id: executive_ids.join(","),
                    }
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.Get_document()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            onChange(page) {
                this.page = page;
                this.Get_document()
            },

            // 类型转换
            wordType(status) {
                let type_arr = ['使用中', '已停用']
                if (type_arr.length >= Number(status)) {
                    return type_arr[Number(status) - 1]
                } else {
                    return status
                }
            },

            // 定义 关闭抽屉时的 函数
            onCloseDrawer() {
                this.drawer_visible = false;
                this.Get_document()
            },
            // 定义 打开抽屉时的 函数
            showDrawer(item) {
                this.edit_time = new Date() / 1;
                this.edit_info = item;
                this.drawer_visible = true;
            },

            // 定义 关闭抽屉时的 编辑
            onCloseDrawer1() {
                this.drawer_visible1 = false;
                this.Get_document()
            },
            // 定义 打开抽屉时的 编辑
            showDrawer1() {
                let id = 0
                let count = 0
                let info = 0
                this.document_list.map((item, key) => {
                    if (item.check) {
                        id = item.id
                        count++
                        info = item
                    }
                })
                if (!id) return layer.msg('请选择执行标准')
                if (count !== 1) return layer.msg('只能选择一个择执行标准进行编辑')
                this.edit_time1 = new Date() / 1;
                this.edit_info1 = info;
                this.drawer_visible1 = true;
            },
            // 定义 关闭抽屉时的 函数
            onCloseDrawer2() {
                this.drawer_visible2 = false;
                this.Get_document()
            },
            // 定义 打开抽屉时的 函数
            showDrawer2() {
                let id = 0
                let count = 0
                let info = 0
                this.document_list.map((item, key) => {
                    if (item.check) {
                        id = item.id
                        count++
                        info = item
                    }
                })
                if (!id) return layer.msg('请选择执行标准')
                if (count !== 1) return layer.msg('只能选择一个择执行标准查看历史记录')
                this.edit_time2 = new Date() / 1;
                this.edit_info2 = info;
                this.drawer_visible2 = true;
            }
        }
    }
</script>

<style scoped>
    /*******************w按钮区开始w*********************
        ***********************************************/
    .btner{
        width: 100%;
        background: #f3f3f3;
        padding: 5px 10px;
        font-size: 12px;
        color: #333;
        border: 1px solid #eeeeee;
        }

    .btnerlist{
        cursor: pointer;
        border-right: 1px solid #e9e8e8;
        height: 26px;
        line-height: 24px;
        padding: 0 12px
        }

    .btner:last-child{ border: none}

    .isthis, .btnerlist:hover{
        background: #1592fe;
        color: #ffffff;
        border-radius: 4px;
        height: 26px;
        line-height: 24px;
        }

    .btnerlist i{
        color: #999999;
        font-size: 16px;
        margin-right: 3px;
        }

    .isthis i, .btnerlist:hover i{ color: #ffffff !important}

    .isDel, .isDel:hover{ background: #ff4d4f;border-radius: 4px; color: #ffffff !important}

    .isDel i, .isDel:hover, .isDel:hover i{ color: #ffffff !important}

    /*******************w按钮区结束w*********************
    ***********************************************/
    .ml5{ margin-left: 5px}

    .ant-table-column-sorter{
        display: inline-flex;
        flex-direction: column;
        margin-top: 0px;
        margin-left: 5px;
        cursor: pointer;
        width: 10px;
        vertical-align: middle;
        }

    .jiantou{
        color: #999999
        }

    .classname{
        color: #1890ff;
        }

    .fl{
        float: left
        }

    .active{ background: #ebf6ff}

    table tr{ cursor: pointer}

</style>
