<template>
    <div>
        <!--:visible="visible"  赋值 显示变量-->
        <!--@close="onClose"    赋值 关闭函数-->
        <a-drawer title="编辑执行标准" width="640" placement="right" :closable="true" :visible="visible" @close="onClose">
            <div>
                <a-form id="components-form-demo-validate-other" v-bind="formItemLayout" @submit="handleSubmit">
                    <a-form-item label="执行标准名称">
                        <a-input v-model="info.name"></a-input>
                    </a-form-item>
                    <a-form-item label="标准编号">
                        <a-input v-model="info.serial"></a-input>
                    </a-form-item>
                    <a-form-item label="标准文件">
                        <div class="btn">
                            <div class="btn-up"><i class="el-icon-upload"></i> 上传文件
                                <input @change="fileUpload" type="file">
                            </div>
                            <div class="btn-new">
                                <span v-if="file" style="opacity: 0">{{ file.name }}</span>
                            </div>
                        </div>
                    </a-form-item>
                    <a-form-item label="文件名称" v-if="info.file_url !== '' " >
                        <a-input v-model="info.file_name"></a-input>
                    </a-form-item>
                    <a-form-item label="状态">
                        <a-radio-group name="radioGroup" v-model="info.status">
                            <a-radio :value="1">
                                使用中
                            </a-radio>
                            <a-radio :value="2">
                                已停用
                            </a-radio>
                        </a-radio-group>
                    </a-form-item>

                    <a-form-item label="备注">
                        <a-textarea v-model="info.remark" placeholder="请输入备注" :auto-size="{ minRows: 3, maxRows: 5 }"/>
                    </a-form-item>
                    <a-form-item label="" :wrapper-col="{ span:14, offset: 6}">
                        <a-button type="primary" @click="doClick()" >确定</a-button>

                    </a-form-item>
                </a-form>
            </div>
        </a-drawer>
    </div>
</template>
<script>
    /***
     Name: example3
     Code: sa0ChunLuyu
     Time: 2021/10/16 09:47
     Remark: 编辑抽屉示例的抽屉组件
     */
    export default {
        // 接收 显示变量 和 关闭函数
        props: ['visible', 'close', 'edit_info', 'edit_time'],
        data: () => ({
            file: false,
            file_url: '',
            info: {
                item_id: 0,
                name: '',
                serial: '',
                file_url: '',
                remark: '',
                status: '',
            },
            formItemLayout: {
                labelCol: {span: 6},
                wrapperCol: {span: 14},
            },
        }),
        watch: {
            edit_time() {
                this.info = JSON.parse(JSON.stringify(this.edit_info))
            },
        },
        mounted() {
            this.info = JSON.parse(JSON.stringify(this.edit_info))
        },
        methods: {
            //文件上传
            fileUpload(e) {
                let file = e.target.files[0]
                console.log(file)
                this.file = file
                this.info.file_name = file.name
                this.fileUploadDo()
            },
            fileUploadDo() {
                let param = new FormData()
                param.append('file', this.file)
                this.$sa0.file({
                    url: this.$api('upload_file'),
                    data: param,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            layer.msg('上传成功')
                            this.info.file_url = res.data.url
                        },
                        error: (res) => {
                            layer.msg('上传失败')
                        }
                    })
                })
            },
            doClick() {
                let data = {
                    id: this.info.id,
                    name: this.info.name,
                    serial: this.info.serial,
                    file_url: this.info.file_url,
                    remark: this.info.remark,
                    status: this.info.status,
                    file_name: this.info.file_name,
                }
                console.log(data)
                this.$sa0.post({
                    url: this.$api('Edit_executive_bz'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            this.close()
                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },
            // 定义 组件 自身的关闭函数
            onClose() {
                this.close();
            },
            handleSubmit(e) {
                e.preventDefault();
                this.form.validateFields((err, values) => {
                    if (!err) {
                        console.log('Received values of form: ', values);
                    }
                });
            },
            normFile(e) {
                console.log('Upload event:', e);
                if (Array.isArray(e)) {
                    return e;
                }
                return e && e.fileList;
            },
        }
    }
</script>
<style scoped>
    #components-form-demo-validate-other .dropbox{
        height: 180px;
        line-height: 1.5;
        }

    .btn{
        position: relative;
        }

    .btn-up{
        display: inline-block;
        position: relative;
        margin-right: 10px;
        padding: 4px 10px;
        background: #09aaff;
        color: #fff;
        border: solid 1px #09aaff;
        border-radius: 3px
        }

    .btn-up input{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0
        }

    .btn-new{
        display: inline-block;
        position: relative;
        padding: 5px 15px;
        /*border:solid 1px #09aaff;*/
        color: #09aaff;
        background: #fff;
        border-radius: 3px
        }

    .btn-up, .btn-up input, .btn-new{
        cursor: pointer;
        }

    .ml5{ margin-left: 10px}

</style>
